import axios from './axiosInstances/identityAxiosInstance';
// import axios from './axiosInstances/testAxios';

const api = {
  async getAbnormalMetrics(departments) {
    return axios.get('insight/metrics', { params: { departments } });
  },
  async getInsightStatus({ departments, date }) {
    return axios.get('insight/status', { params: { departments, date } });
  },
  async getInsightsDateRange({ from, to, departments, category, groupBy }) {
    return axios.get('insight/get-all', {
      params: { to, from, departments, category, groupBy },
    });
  },
  async getDynamicsInputRange({ from, to, department, departments, groupBy }) {
    return axios.get('insight/dynamic-inputs', {
      params: { to, from, department, departments, groupBy },
    });
  },
  async getEmployeesVitals() {
    return axios.get('insight/vitals/patient-with-details');
  },
};

export default api
