import React, { useState, useMemo } from 'react';
import { Icon, Table, Button } from 'semantic-ui-react';
import { Text } from 'components';

const OverviewTable = ({ data, clientSummaries }) => {
  const [copyStatus, setCopyStatus] = useState('');
  const [textIndex, setTextIndex] = useState(null);
  debugger
  // Calculate summary data with combined statuses

  // Calculate totals for the summary footer
  const summaryTotals = useMemo(() => {
    return clientSummaries.reduce((totals, row) => ({
      totalPatients: totals.totalPatients + row.totalPatients,
      totalEmployees: totals.totalEmployees + row.totalEmployees,
      hasEmployee: totals.hasEmployee + row.hasEmployee,
      hasVital: totals.hasVital + row.hasVital,
      hasResult: totals.hasResult + row.hasResult,
      hasEmployeeAndVital: totals.hasEmployeeAndVital + row.hasEmployeeAndVital,
      hasEmployeeAndResult: totals.hasEmployeeAndResult + row.hasEmployeeAndResult
    }), {
      totalPatients: 0,
      totalEmployees: 0,
      hasEmployee: 0,
      hasVital: 0,
      hasResult: 0,
      hasEmployeeAndVital: 0,
      hasEmployeeAndResult: 0
    });
  }, [clientSummaries]);

  const copyText = (text, index) => {
    setTextIndex(index);
    navigator.clipboard.writeText(text).then(
        () => {
          setCopyStatus('Copied to clipboard!');
          setTimeout(() => setCopyStatus(''), 2000);
        },
        (err) => {
          console.error('Failed to copy text: ', err);
          setCopyStatus('Failed to copy!');
          setTimeout(() => setCopyStatus(''), 2000);
        }
    );
  };

  const downloadCSV = () => {
    const headers = [
      'Client',
      'Name',
      'Email',
      'PassNo - SSN',
      'URL',
      'Employee',
      'Vital',
      'Result'
    ];

    const csvData = data.map(emp => [
      emp?.clientName || '',
      emp?.name || '',
      emp?.email || '',
      `${emp?.passNo || ''} - ${emp?.ssn || ''}`,
      emp?.url || '',
      emp?.hasEmployee ? 'YES' : 'NO',
      emp?.hasVital ? 'YES' : 'NO',
      emp?.hasResult ? 'YES' : 'NO'
    ]);

    csvData.unshift(headers);

    const csvString = csvData
        .map(row =>
            row.map(cell =>
                typeof cell === 'string' && cell.includes(',')
                    ? `"${cell}"`
                    : cell
            ).join(',')
        )
        .join('\n');

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'overview_data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderTableData = () => {
    return data.map((emp, index) => {
      return (
          <Table.Row key={emp?._id}>
            <Table.Cell>
              <div>
                <Text size="small" className="mr-1">
                  {emp?.clientName}
                </Text>
              </div>
            </Table.Cell>
            <Table.Cell>
              <Text size="tiny">{emp?.name || ''}</Text>
            </Table.Cell>
            <Table.Cell>
              <Text size="tiny">{emp?.email || ''}</Text>
            </Table.Cell>
            <Table.Cell>
              <Text size="small">{emp?.passNo || ''} - {emp?.ssn}</Text>
            </Table.Cell>
            <Table.Cell>
              <div style={styles.container}>
                <Text size="small" style={{ ...styles.text, maxWidth: `200px` }}>
                  {emp?.url}
                </Text>
                <Icon
                    style={styles.button}
                    onClick={() => copyText(emp?.url, index)}
                    name="copy outline"
                />
              </div>
              {copyStatus && textIndex === index && (
                  <div style={styles.status}>{copyStatus}</div>
              )}
            </Table.Cell>
            <Table.Cell>
              <Text size="small">{emp?.hasEmployee ? 'YES' : 'NO'}</Text>
            </Table.Cell>
            <Table.Cell>
              <Text size="small">{emp?.hasVital ? 'YES' : 'NO'}</Text>
            </Table.Cell>
            <Table.Cell>
              <Text size="small">{emp?.hasResult ? 'YES' : 'NO'}</Text>
            </Table.Cell>
          </Table.Row>
      );
    });
  };

  const renderSummaryTable = () => {
    return (
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Client Name</Table.HeaderCell>
              <Table.HeaderCell>Total Patients</Table.HeaderCell>
              <Table.HeaderCell>Vital Or Result</Table.HeaderCell>
              <Table.HeaderCell>Employee</Table.HeaderCell>
              <Table.HeaderCell>Vital</Table.HeaderCell>
              <Table.HeaderCell>Result</Table.HeaderCell>
              <Table.HeaderCell>Employee + Vital</Table.HeaderCell>
              <Table.HeaderCell>Employee + Result</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {clientSummaries.map((summary) => (
                <Table.Row key={summary.clientName}>
                  <Table.Cell>
                    <Text size="small">{summary.clientName}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text size="small">{summary.totalPatients}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text size="small">{summary.totalEmployees}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text size="small">{summary.hasEmployee}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text size="small">{summary.hasVital}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text size="small">{summary.hasResult}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text size="small">{summary.hasEmployeeAndVital}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text size="small">{summary.hasEmployeeAndResult}</Text>
                  </Table.Cell>
                </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>
                <Text size="small" style={styles.bold}>Total</Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text size="small" style={styles.bold}>{summaryTotals.totalPatients}</Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text size="small" style={styles.bold}>{summaryTotals.totalEmployees}</Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text size="small" style={styles.bold}>{summaryTotals.hasEmployee}</Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text size="small" style={styles.bold}>{summaryTotals.hasVital}</Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text size="small" style={styles.bold}>{summaryTotals.hasResult}</Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text size="small" style={styles.bold}>{summaryTotals.hasEmployeeAndVital}</Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text size="small" style={styles.bold}>{summaryTotals.hasEmployeeAndResult}</Text>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
    );
  };

  return (
      <div>
        <div style={styles.buttonContainer}>
          <Button
              icon
              labelPosition='left'
              primary
              onClick={downloadCSV}
              size='small'
          >
            <Icon name='download' />
            Download CSV
          </Button>
        </div>

        <div style={styles.summaryContainer}>
          <Text size="medium" style={styles.summaryTitle}>Summary by Client</Text>
          {renderSummaryTable()}
        </div>

        <Table className="overview-table" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Client</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>PassNo - SSN.</Table.HeaderCell>
              <Table.HeaderCell>URL</Table.HeaderCell>
              <Table.HeaderCell>Employee</Table.HeaderCell>
              <Table.HeaderCell>Vital</Table.HeaderCell>
              <Table.HeaderCell>Result</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{renderTableData()}</Table.Body>
        </Table>
      </div>
  );
};

export default OverviewTable;

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  button: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    fontSize: '16px',
  },
  status: {
    marginTop: '5px',
    fontSize: '14px',
    color: 'green',
  },
  buttonContainer: {
    marginBottom: '1rem',
  },
  summaryContainer: {
    marginBottom: '2rem',
  },
  summaryTitle: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  bold: {
    fontWeight: 'bold',
  }
};
