import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { debounce } from 'lodash';
import { Loader } from 'semantic-ui-react';

import { Text, ErrorToast, Select, Search, DatePicker, Icon } from 'components';

import InsightsService from 'api/insights';

import OverviewTable from './components/overview/overview';
import history from '../../history';

const LabResult = () => {
  const userInfo = useSelector((state) => state.general.user);

  const [isLoading, setLoading] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [clientSummaries, setClientSummaries] = useState([]);

  useEffect(() => {
    if (!userInfo?.email?.includes('@riverr.ai'))
      return history.push('/workforce');
    fetchEmployeeVitals();
  }, []);

  const fetchEmployeeVitals = async () => {
    try {
      setLoading(true);
      const { data } = await InsightsService.getEmployeesVitals();
      setPatientData(data?.data.patients);
      setClientSummaries(data?.data.clientSummaries);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mental-Peme">
        <div className="mental-Peme__header">
          {/* <MentalHealthIcon
            className="mental-Peme__header__icon"
            color="black"
          /> */}
          <Text bold size="bigger">
            Overview
          </Text>
        </div>
        <div className="mental-Peme__table-wrapper mt-4">
          {isLoading ? (
            <Loader active inline="centered" size="small" />
          ) : (
            <OverviewTable data={patientData} clientSummaries={clientSummaries} />
          )}
        </div>
      </div>
    </>
  );
};
export default LabResult;
